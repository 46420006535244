<template>
  <div class="home flex">
    <div class="hero h-full">
      <div class="hero--content container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
        <div class="hero-wrapper grid grid-cols-1 md:grid-cols-12 gap-8 items-center py-5 md:py-0">
          <div class="hero-text col-span-6">
            <div class=" flex flex-row-reverse">
              <div style="width:100%; display:flex; flex-direction:column">
                <h1 class="brandsHero font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight m-auto">
                  Mille et <b>1</b> pains
                </h1>
                <p class="text-gray-800 text-base leading-relaxed mt-8 font-semibold max-w-md m-auto">
                  Notre boulangerie, Mille et 1 pains, vous propose chaque jour une trentaine de pains artisanaux réalisés sur place par notre artisan de talent.
                  Pains blancs, baguettes, pains classiques, pains spéciaux, viennoiseries…,
                  faites confiance à votre boulanger pour vous proposer des pains frais et d’excellente qualité toute l’année.
                  Rendez-vous à la boutique pour découvrir les créations du moment.
                </p>
              </div>
            </div>
          </div>
          <div class="hero-image col-span-6">
            <img
              src="/img/home3.png"
              alt="hero"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  layout: 'default'
}
</script>
<style lang="scss" scoped>

.home {

  height: auto;
  margin: auto;
  .hero {
    display: flex;
    &--content {
      margin: auto;
    }
  }
  .brandsHero {
    color: #CD9128;
    font-size: 6em;
    font-family: 'Amatic SC', cursive;
  }
}

@media screen AND (max-width:42em) {
  .brandsHero {
    font-size: 4em !important;
  }
}

</style>
